import React, { useContext, useEffect, useState } from "react"
import { UseCasesContext } from "../../../context/useCases"
import { Link, useParams } from "react-router-dom"

import { Menu } from "../../components/menu"
import { OktellService } from "../../../repository/api/oktell"
import { ReportReport } from "../../../repository/api/generated"
import { TableRow } from "./tableRow"

export const ReportListPage = () => {
    const [statusesList, setStatusesList] = useState<string[]>([])
    const [reports, setReports] = useState<ReportReport>()
    let { id } = useParams();
    let useCases = useContext(UseCasesContext)
    const [filter, setFilter] = useState<{
        start: string,
        end: string,
        statuses: string[],
    }>({
        start: "",
        end: "",
        statuses: [],
    })

    const readStatusesList = async () => {
        let service = new OktellService()
        let response = await service.ReadStatusesList()
        if (response instanceof Error) {

        } else {
            setStatusesList([...response])
        }
    }

    const toggleStatus = (status:string) => {
        if (filter.statuses.includes(status)) {
            setFilter({
                ...filter,
                statuses: filter.statuses.filter((item)=> item != status)
            })
        } else {
            let statuses = filter.statuses
            statuses.push(status)
            setFilter({
                ...filter,
                statuses: statuses
            })
        }
    }

    useEffect(() => {
        readStatusesList()
        let dateNow = new Date()
        dateNow.setDate(dateNow.getDate() - 1)
        let start = dateNow.toISOString().split('T')[0]
        dateNow.setDate(dateNow.getDate() + 1)
        let end = dateNow.toISOString().split('T')[0]
        setFilter({
            ...filter,
            start: start,
            end: end,
        })
    }, [])

    const readReports = async () => {
        let service = new OktellService()
        let response = await service.ReadReportsList(formatDate(new Date(filter.start)), formatDate(new Date(filter.end)), filter.statuses)
        
        if (response instanceof Error) {

        } else {
            setReports(response)
        }
    }

    useEffect(()=>{
        if (filter.start != "") {
            readReports()
        }
    },[filter])

    const formatDate = (day: Date) => {
        const yyyy = day.getFullYear();
        let mm = (day.getMonth() + 1).toString(); // Months start at 0!
        let dd = day.getDate().toString();

        let hh = day.getHours().toString();
        let min = day.getMinutes().toString();

        if (dd.length == 1) dd = '0' + dd;
        if (mm.length == 1) mm = '0' + mm;

        if (hh.length == 1) hh = '0' + hh;
        if (min.length == 1) min = '0' + min;

        return dd + '.' + mm + "."+ yyyy ;
    }




    return (
        <React.Fragment>
            <div className="layout-page" >
                {/*<Menu />*/}
                <div className="content-wrapper" >
                    <div className="container-xxl flex-grow-1 container-p-y">
                        <div className="row mx-1">

                            <div className="card mb-4">
                                <div className="card-body">
                                    <h5 className="card-header" style={{ marginBottom: 20, padding: 0 }}>Фильтры</h5>
                                    <div className="row" style={{ marginBottom: 15, padding: 0 }}>
                                        <div className="col-12 col-sm-6 col-lg-4 justify-content-end">
                                            <label className="form-label">Дата с:</label>
                                            <input type="date" value={filter?.start} name="from" onChange={(e) => {let date = new Date(e.target.value); setFilter({...filter, start: date.toISOString().split("T")[0]})}} className="form-control dt-input dt-full-name" />
                                        </div>
                                        <div className="col-12 col-sm-6 col-lg-4">
                                            <label className="form-label">Дата по:</label>
                                            <input type="date" value={filter?.end} name="to" onChange={(e) => {let date = new Date(e.target.value); setFilter({...filter, end: date.toISOString().split("T")[0]})}} className="form-control dt-input dt-full-name" />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="small fw-semibold">Выберите статусы</div>
                                        <div style={{ display: "flex", flexWrap: "wrap" }}>
                                            {statusesList.map((status) => {
                                                return <div className="demo-inline-spacing">
                                                    <span className={filter.statuses.includes(status)?"badge rounded-pill bg-primary":"badge rounded-pill bg-secondary"} onClick={() => {toggleStatus(status)}} style={{cursor: "pointer"}}>{status}</span>
                                                </div>
                                            })}
                                        </div>
                                    </div>

                                </div>
                            </div>
                            {<div className="table-responsive text-nowrap">
                                    <table className="table mt-1">
                                        <thead>
                                            <tr>
                                                <th>Дата</th>
                                                <th>Ссылка</th>
                                            </tr>
                                        </thead>
                                        <tbody className="table table-hover">
                                            {reports?.report_types && reports?.report_types.map((reportType) => {
                                                return reportType.report_files?.map((reportFile)=>{
                                                    return <TableRow file={reportFile} />
                                                })
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>

        </React.Fragment>


    )

}