import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { Login } from "../../pages/auth/login/login";
import { useTypedSelector } from "../../../hooks/useTypedSelector";
import { useContext, useEffect } from "react";
import { UseCasesContext } from "../../../context/useCases";
import { ReportListPage } from "../../pages/report-list";

export const RouterComponent = () => {
  let useCases = useContext(UseCasesContext)

  const login = useTypedSelector(({ login }) => {
    return login
  })

  useEffect(() => {
    useCases?.authUseCase.CheckAuthorization()
    setInterval(()=>{
      useCases?.authUseCase.CheckAuthorization()
    },10000)
  }, [])
 
  
  return (<Router basename="/">
    <Routes>
      {login?.authorized ? <>
        <Route path="*" element={<ReportListPage />} />
      </> : <>
        <Route path="*" element={<Login />} />
      </>}
    </Routes>
  </Router>
  )
}