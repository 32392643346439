import axios from "axios";
import { Configuration, OktellApi, ReportReport } from "../generated";

export class OktellService {
    private service: OktellApi;

    constructor() {
        this.service = new OktellApi(new Configuration(), process.env.REACT_APP_BACKEND_URL)
    }

    async ReadReportsList(dateStart: string, dateEnd: string, status: string[],): Promise<ReportReport | Error> {
        try {
            let response = await this.service.apiOktellGet(dateStart, dateEnd, status,  { headers: { "Authorization": localStorage.getItem("token") } })

            if (response.status === 200 || response.status === 201) {
                
                return response.data
            } else {
                if (axios.isAxiosError(response)) {
                    return Error(response?.message)
                }
                return Error("something went wrong")
            }
        } catch (e) {
            let error: string = ""
            if (axios.isAxiosError(e)) {
                error = e.response?.data.message
            }

            return Error(error)
        }
    }

    async ReadStatusesList(): Promise<string[] | Error> {
        try {
            let response = await this.service.apiOktellStatusesGet({ headers: { "Authorization": localStorage.getItem("token") } })

            if (response.status === 200 || response.status === 201) {
                
                return response.data
            } else {
                if (axios.isAxiosError(response)) {
                    return Error(response?.message)
                }
                return Error("something went wrong")
            }
        } catch (e) {
            let error: string = ""
            if (axios.isAxiosError(e)) {
                error = e.response?.data.message
            }

            return Error(error)
        }
    }


}