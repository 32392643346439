/* tslint:disable */
/* eslint-disable */
/**
 * phone_numbers_database backend
 * phone_numbers_database backend
 *
 * The version of the OpenAPI document: 1.0
 * Contact: kanya384@mail.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface AuthSignInRequest
 */
export interface AuthSignInRequest {
    /**
     * 
     * @type {string}
     * @memberof AuthSignInRequest
     */
    'login'?: string;
    /**
     * 
     * @type {string}
     * @memberof AuthSignInRequest
     */
    'pass'?: string;
}
/**
 * 
 * @export
 * @interface DeliveryErrorResponse
 */
export interface DeliveryErrorResponse {
    /**
     * 
     * @type {string}
     * @memberof DeliveryErrorResponse
     */
    'message'?: string;
}
/**
 * 
 * @export
 * @interface OktellInternalDeliveryAuthSignInResponse
 */
export interface OktellInternalDeliveryAuthSignInResponse {
    /**
     * 
     * @type {string}
     * @memberof OktellInternalDeliveryAuthSignInResponse
     */
    'refreshToken'?: string;
    /**
     * 
     * @type {string}
     * @memberof OktellInternalDeliveryAuthSignInResponse
     */
    'token'?: string;
}
/**
 * 
 * @export
 * @interface OktellUploadOktellReportResponse
 */
export interface OktellUploadOktellReportResponse {
    /**
     * 
     * @type {string}
     * @memberof OktellUploadOktellReportResponse
     */
    'status'?: string;
}
/**
 * 
 * @export
 * @interface ReportReport
 */
export interface ReportReport {
    /**
     * 
     * @type {Array<ReportReportType>}
     * @memberof ReportReport
     */
    'report_types'?: Array<ReportReportType>;
}
/**
 * 
 * @export
 * @interface ReportReportFile
 */
export interface ReportReportFile {
    /**
     * 
     * @type {string}
     * @memberof ReportReportFile
     */
    'date'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ReportReportFile
     */
    'error'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ReportReportFile
     */
    'report_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReportReportFile
     */
    'url'?: string;
}
/**
 * 
 * @export
 * @interface ReportReportType
 */
export interface ReportReportType {
    /**
     * 
     * @type {string}
     * @memberof ReportReportType
     */
    'name'?: string;
    /**
     * 
     * @type {Array<ReportReportFile>}
     * @memberof ReportReportType
     */
    'report_files'?: Array<ReportReportFile>;
}

/**
 * AuthApi - axios parameter creator
 * @export
 */
export const AuthApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * авторизация пользователя.
         * @summary авторизация пользователя.
         * @param {AuthSignInRequest} user Данные для аутентификации
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAuthSignInPost: async (user: AuthSignInRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'user' is not null or undefined
            assertParamExists('apiAuthSignInPost', 'user', user)
            const localVarPath = `/api/auth/sign-in`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(user, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AuthApi - functional programming interface
 * @export
 */
export const AuthApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AuthApiAxiosParamCreator(configuration)
    return {
        /**
         * авторизация пользователя.
         * @summary авторизация пользователя.
         * @param {AuthSignInRequest} user Данные для аутентификации
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAuthSignInPost(user: AuthSignInRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OktellInternalDeliveryAuthSignInResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAuthSignInPost(user, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AuthApi - factory interface
 * @export
 */
export const AuthApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AuthApiFp(configuration)
    return {
        /**
         * авторизация пользователя.
         * @summary авторизация пользователя.
         * @param {AuthSignInRequest} user Данные для аутентификации
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAuthSignInPost(user: AuthSignInRequest, options?: any): AxiosPromise<OktellInternalDeliveryAuthSignInResponse> {
            return localVarFp.apiAuthSignInPost(user, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AuthApi - object-oriented interface
 * @export
 * @class AuthApi
 * @extends {BaseAPI}
 */
export class AuthApi extends BaseAPI {
    /**
     * авторизация пользователя.
     * @summary авторизация пользователя.
     * @param {AuthSignInRequest} user Данные для аутентификации
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public apiAuthSignInPost(user: AuthSignInRequest, options?: AxiosRequestConfig) {
        return AuthApiFp(this.configuration).apiAuthSignInPost(user, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * OktellApi - axios parameter creator
 * @export
 */
export const OktellApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Получить список отчетов октелл.
         * @summary Получить список отчетов октелл.
         * @param {string} dateStart Дата с
         * @param {string} dateEnd Дата по
         * @param {Array<string>} [status] Статусы
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOktellGet: async (dateStart: string, dateEnd: string, status?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'dateStart' is not null or undefined
            assertParamExists('apiOktellGet', 'dateStart', dateStart)
            // verify required parameter 'dateEnd' is not null or undefined
            assertParamExists('apiOktellGet', 'dateEnd', dateEnd)
            const localVarPath = `/api/oktell/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (dateStart !== undefined) {
                localVarQueryParameter['dateStart'] = dateStart;
            }

            if (dateEnd !== undefined) {
                localVarQueryParameter['dateEnd'] = dateEnd;
            }

            if (status) {
                localVarQueryParameter['status'] = status.join(COLLECTION_FORMATS.csv);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Загрузка отчета по звонкам октелл.
         * @summary Загрузка отчета по звонкам октелл.
         * @param {File} file Файл для загрузки
         * @param {string} [date] 
         * @param {string} [type] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOktellPost: async (file: File, date?: string, type?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'file' is not null or undefined
            assertParamExists('apiOktellPost', 'file', file)
            const localVarPath = `/api/oktell/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();


            if (date !== undefined) { 
                localVarFormParams.append('date', date as any);
            }
    
            if (type !== undefined) { 
                localVarFormParams.append('type', type as any);
            }
    
            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Получить список типов отчетов октелл.
         * @summary Получить список типов отчетов октелл.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOktellStatusesGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/oktell/statuses`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OktellApi - functional programming interface
 * @export
 */
export const OktellApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = OktellApiAxiosParamCreator(configuration)
    return {
        /**
         * Получить список отчетов октелл.
         * @summary Получить список отчетов октелл.
         * @param {string} dateStart Дата с
         * @param {string} dateEnd Дата по
         * @param {Array<string>} [status] Статусы
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOktellGet(dateStart: string, dateEnd: string, status?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReportReport>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOktellGet(dateStart, dateEnd, status, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Загрузка отчета по звонкам октелл.
         * @summary Загрузка отчета по звонкам октелл.
         * @param {File} file Файл для загрузки
         * @param {string} [date] 
         * @param {string} [type] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOktellPost(file: File, date?: string, type?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OktellUploadOktellReportResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOktellPost(file, date, type, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Получить список типов отчетов октелл.
         * @summary Получить список типов отчетов октелл.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOktellStatusesGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOktellStatusesGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * OktellApi - factory interface
 * @export
 */
export const OktellApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = OktellApiFp(configuration)
    return {
        /**
         * Получить список отчетов октелл.
         * @summary Получить список отчетов октелл.
         * @param {string} dateStart Дата с
         * @param {string} dateEnd Дата по
         * @param {Array<string>} [status] Статусы
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOktellGet(dateStart: string, dateEnd: string, status?: Array<string>, options?: any): AxiosPromise<ReportReport> {
            return localVarFp.apiOktellGet(dateStart, dateEnd, status, options).then((request) => request(axios, basePath));
        },
        /**
         * Загрузка отчета по звонкам октелл.
         * @summary Загрузка отчета по звонкам октелл.
         * @param {File} file Файл для загрузки
         * @param {string} [date] 
         * @param {string} [type] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOktellPost(file: File, date?: string, type?: string, options?: any): AxiosPromise<OktellUploadOktellReportResponse> {
            return localVarFp.apiOktellPost(file, date, type, options).then((request) => request(axios, basePath));
        },
        /**
         * Получить список типов отчетов октелл.
         * @summary Получить список типов отчетов октелл.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOktellStatusesGet(options?: any): AxiosPromise<Array<string>> {
            return localVarFp.apiOktellStatusesGet(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * OktellApi - object-oriented interface
 * @export
 * @class OktellApi
 * @extends {BaseAPI}
 */
export class OktellApi extends BaseAPI {
    /**
     * Получить список отчетов октелл.
     * @summary Получить список отчетов октелл.
     * @param {string} dateStart Дата с
     * @param {string} dateEnd Дата по
     * @param {Array<string>} [status] Статусы
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OktellApi
     */
    public apiOktellGet(dateStart: string, dateEnd: string, status?: Array<string>, options?: AxiosRequestConfig) {
        return OktellApiFp(this.configuration).apiOktellGet(dateStart, dateEnd, status, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Загрузка отчета по звонкам октелл.
     * @summary Загрузка отчета по звонкам октелл.
     * @param {File} file Файл для загрузки
     * @param {string} [date] 
     * @param {string} [type] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OktellApi
     */
    public apiOktellPost(file: File, date?: string, type?: string, options?: AxiosRequestConfig) {
        return OktellApiFp(this.configuration).apiOktellPost(file, date, type, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Получить список типов отчетов октелл.
     * @summary Получить список типов отчетов октелл.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OktellApi
     */
    public apiOktellStatusesGet(options?: AxiosRequestConfig) {
        return OktellApiFp(this.configuration).apiOktellStatusesGet(options).then((request) => request(this.axios, this.basePath));
    }
}


