import { Fragment, useContext, useState } from "react";
import { ChevronRight, DotsVertical, Edit, Eye, Refresh, Trash } from 'tabler-icons-react'
import { UseCasesContext } from "../../../context/useCases";
import { Link, useParams } from "react-router-dom";

import { useTypedSelector } from "../../../hooks/useTypedSelector";
import { Info } from "react-feather";
import { ReportReportFile } from "../../../repository/api/generated";

export const TableRow = (props: { file: ReportReportFile}) => {
    let { id } = useParams();
    const [showMenu, setShowMenu] = useState(false)
    let useCases = useContext(UseCasesContext)



    const formatDate = (input: string) => {
        const today = new Date(input);
        const yyyy = today.getFullYear();
        let mm = (today.getMonth() + 1).toString(); // Months start at 0!
        let dd = today.getDate().toString();

        let hh = today.getHours().toString();
        let min = today.getMinutes().toString();

        if (dd.length == 1) dd = '0' + dd;
        if (mm.length == 1) mm = '0' + mm;

        if (hh.length == 1) hh = '0' + hh;
        if (min.length == 1) min = '0' + min;

        return dd + '.' + mm + " "+ yyyy ;
    }

    
    return (<tr>
        <td>{formatDate(props.file.date!)}</td>
        <td><a href={props.file.url} download={true} style={{color: props.file.error? "red":""}}>{props.file.report_name}</a></td>
    </tr>)
}
